app.factory('userModel', ['$http','$state','$cookies','$cookieStore', function($http,$state,$cookies,$cookieStore){
	userModel = {};

	userModel.doLogin = function(user){ 
		var data = {};
		data._token = csrfToken;
		data.user = user;
		return $http({
			url: baseUrl+'auth',
			method: 'POST',
			data: data
		});
	}

	userModel.authenticate = function(ctr){
		var data = {};
		data._token = csrfToken;
		data.ctr = ctr;
		return $http({
			url: baseUrl+'authenticate',
			method: 'POST',
			data: data
		});
	}
	userModel.authenticatelogin = function(){
		var data = {};
		data._token = csrfToken;
		return $http({
			url: baseUrl+'authenticatelogin',
			method: 'POST',
			data: data
		});
	}
	userModel.authenticateForgotapi = function(){
		var data = {};
		data._token = csrfToken;
		return $http({
			url: baseUrl+'authenticateForgotApi',
			method: 'POST',
			data: data
		});
	}

	userModel.authenticateForgot = function(ctr){
		var data = {};
		data._token = csrfToken;
		data.ctr = ctr;
		return $http({
			url: baseUrl+'authenticateForgot',
			method: 'POST',
			data: data
		});
	}
	userModel.authenticateReg = function(){
		var data = {};
		data._token = csrfToken;
		return $http({
			url: baseUrl+'authenticateReg',
			method: 'POST',
			data: data
		});
	}
	userModel.chekforgot = function(user){
		var data = {};
		data._token = csrfToken;
		data.user = user;
		return $http({
			url: baseUrl+'chekforgot',
			method: 'POST',
			data: data
		});
	}

	userModel.doGuestLogin = function(user){ 
	// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.user = user;
		return $http({
			url: baseUrl+'authGuest',
			method: 'POST',
			data: data
		});
	}


	userModel.getAuthStatus = function(){
		// var status = $cookies.get('auth');
		var status = sessionStorage.authData;
		// console.log(status);
		if(status){
			return true;
		} else {
			return false;
		}
	}

	userModel.getUserObj = function() {
		// var userObj = angular.fromJson($cookies.get('auth'));
		var userObj = angular.fromJson(sessionStorage.authData);
		return userObj;
	}

	userModel.getFund = function (id,district_id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
	    data.district_id = district_id;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'fundsAvailbility',
			method: 'POST',
			data: data
		});
	}

	userModel.getLogCount = function(user){
		var data = {};
		data._token = csrfToken;
		data.user = user;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'getLogCount',
			method: 'POST',
			data: data
		});
	}

	userModel.doLogout = function(data){ 
		//console.log(data);
		$cookies.remove('auth');
		sessionStorage.clear();
		return $http({
			url: baseUrl+'logout',
			method: 'POST',
			data:data
		});
	}

	userModel.decryptCredentials = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
        var password = atob(password);
        var decrypted = CryptoJS.AES.decrypt(password, key, {iv:iv});
        //decrypted = decrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return decrypted;
	}

	userModel.encryptCredentialss = function(password,count){
		//console.log(password);
		if(count==1 || count==0){
			var key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
		}else if(count==2){
			var key = CryptoJS.enc.Hex.parse("0123456789abcfde0123456789abcfde");
        	var iv =  CryptoJS.enc.Hex.parse("abcfde9876543210abcfde9876543210");
		}else if(count==3){
			var key = CryptoJS.enc.Hex.parse("0134567892abcdef0134567892abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef2987654310abcdef2987654310");
		}else if(count==4){
			var key = CryptoJS.enc.Hex.parse("0124567893abcdef0124567893abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef3987654210abcdef3987654210");
		}else if(count==5){
			var key = CryptoJS.enc.Hex.parse("0123567894abcdef0123567894abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef4987653210abcdef4987653210");
		}else if(count==6){
			var key = CryptoJS.enc.Hex.parse("0123467895abcdef0123467895abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef5987643210abcdef5987643210");
		}else if(count==7){
			var key = CryptoJS.enc.Hex.parse("0123457896abcdef0123457896abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef6987543210abcdef6987543210");
		}else if(count==8){
			var key = CryptoJS.enc.Hex.parse("0123456897abcdef0123456897abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef7986543210abcdef7986543210");
		}else if(count==9){
			var key = CryptoJS.enc.Hex.parse("0123456798abcdef0123456798abcdef");
        	var iv =  CryptoJS.enc.Hex.parse("abcdef8976543210abcdef8976543210");
		}else if(count==10){
			var key = CryptoJS.enc.Hex.parse("0123456789adefbc0123456789adefbc");
        	var iv =  CryptoJS.enc.Hex.parse("adefbc9876543210adefbc9876543210");
		}
		
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}

	userModel.encryptCredentials = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}

	userModel.encryptCredentials1 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123456789abcfde0123456789abcfde");
        var iv =  CryptoJS.enc.Hex.parse("abcfde9876543210abcfde9876543210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}
	userModel.encryptCredentials2 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0134567892abcdef0134567892abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef2987654310abcdef2987654310");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}

	userModel.encryptCredentials3 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0124567893abcdef0124567893abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef3987654210abcdef3987654210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}
	userModel.encryptCredentials4 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123567894abcdef0123567894abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef4987653210abcdef4987653210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}
	userModel.encryptCredentials5 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123467895abcdef0123467895abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef5987643210abcdef5987643210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}

	userModel.encryptCredentials6 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123457896abcdef0123457896abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef6987543210abcdef6987543210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}

	userModel.encryptCredentials7 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123456897abcdef0123456897abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef7986543210abcdef7986543210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}
	userModel.encryptCredentials8 = function(password){
		//console.log(password);
		var key = CryptoJS.enc.Hex.parse("0123456798abcdef0123456798abcdef");
        var iv =  CryptoJS.enc.Hex.parse("abcdef8976543210abcdef8976543210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}

	userModel.encryptCredentials9 = function(password){
		//console.log(password);
		//var key = CryptoJS.enc.Hex.parse("9012345678abcdef9012345678abcdef");
        //var iv =  CryptoJS.enc.Hex.parse("abcdef8765432109abcdef8765432109");
        var key = CryptoJS.enc.Hex.parse("0123456789adefbc0123456789adefbc");
        var iv =  CryptoJS.enc.Hex.parse("adefbc9876543210adefbc9876543210");
        var encrypted = CryptoJS.AES.encrypt(password, key, {iv:iv});
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

        return encrypted;
	}
	userModel.LogoutCookie = function(){
		
	  return $http.get(baseUrl+'chklogin');
	}
    
	userModel.productlist = function(){
	  return $http.get(baseUrl+'productlist');
	}
	userModel.getAadharVerified = function(auth_data,captoken){
		var data = {};
		data._token = csrfToken;
		data.auth_data = auth_data;
		data.token = captoken;
		return $http({
			url: baseUrl+'aadharverification',
			method: 'POST',
			data: data
		});
	}
	userModel.getAadharVerifiedBulk = function(auth_data){
		var data = {};
		data._token = csrfToken;
		data.auth_data = auth_data;
		return $http({
			url: baseUrl+'aadharverificationBulk',
			method: 'POST',
			data: data
		});
	}

	userModel.isAadharExist = function(aadhar_id){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.aadhar_id = aadhar_id;
		return $http({
			url: baseUrl+'isAadharExist',
			method: 'POST',
			data: data
		});
	}
	userModel.isMobExist = function(mobile_no, user_id){
		//alert(userModel.isMobExist);
		var data = {};
		data._token = csrfToken;
		data.mobile_no = mobile_no;
		//console.log(data);
		if(user_id!=''){
			data.user_id = user_id;
		}
		return $http({
			url: baseUrl+'isMobileExist',
			method: 'POST',
			data: data
		});
	}
	userModel.ifscCodeValidate = function(ifsc){
		//alert(userModel.isMobExist);
		var data = {};
		data._token = csrfToken;
		data.ifsc = ifsc;
		//console.log(data);
		return $http({
			url: baseUrl+'ifscCodeValidate',
			method: 'POST',
			data: data
		});
	}

	userModel.verifyOTP = function(otp){
		var data = {};
		data._token = csrfToken;
		data.otp = otp;
		return $http({
			url: baseUrl+'otpverification',
			method: 'POST',
			data: data
		});
	}

	userModel.getUserDetails = function (app_no,loi_reject_count){
		return $http.get(baseUrl+'admin/manage-applications/user',{params:{'obj':app_no,'count':loi_reject_count}});
	}

	userModel.registerUpdateProfile = function(data){
		data._token = csrfToken;
		return $http({
			url: baseUrl+'register-update-profile',
			method: 'POST',
			data: data
		});
	}
	
	userModel.profileUpdate = function(data, contentType){
		data._token = csrfToken;

		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'profileInfoUpdate',
			method:'POST',
			data: data
		});
	}
	userModel.saveSanctionLetter = function(data, contentType){
		data._token = csrfToken;

		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'saveSanctionLetter',
			method:'POST',
			data: data
		});
	}
    userModel.updateloidetails = function(app_no,bene_id){
		var data = {};
		//data._token = csrfToken;
		data.app_no = app_no;
		data.bene_id = bene_id;

		return $http({
			
			url: baseUrl+'updateloidetails',
			method:'POST',
			data: data
		});
	}

	userModel.updateloidetailsnew = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'updateloidetailsnew',
			method: 'POST',
			data: data
		});
  	}
	userModel.profilePhoto = function(data, contentType){
		data._token = csrfToken;

		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'profilePhoto',
			method:'POST',
			data: data
		});
	}
	userModel.updateUserinfo = function(userinfo,register){
		var data = {};
		data.info = userinfo;
		data.register = register;
		return $http({
			url: baseUrl+'profileInfoUpdate',
			method:'POST',
			data: data
		});
	}

	userModel.updateUser = function(aadhar){
		var data = {};
		data.aadhar = aadhar;
		return $http({
			url: baseUrl+'updateUser',
			method:'POST',
			data: data
		});
	}

	userModel.saveinfo = function(profiledata){
		var data = {};
		data.profiledata = profiledata;
		
		return $http({
			url: baseUrl+'saveinfo',
			method:'POST',
			data: data
		});
	}
	userModel.isAadharExistEdit = function(data){
		data._token = csrfToken;
		return $http({
			url: baseUrl+'isAadharExistEdit',
			method: 'POST',
			data: data
		});
	}

	userModel.resetpassword = function(data){
		data._token = csrfToken;
		return $http({
			url: baseUrl+'resetpassword',
			method: 'POST',
			data: data
		});
	}

	userModel.profileData = function(aadhar_id){
		var data = {};
		data._token = csrfToken;
		data.aadhar_id = aadhar_id;
		return $http({
			url: baseUrl+'profiledata',
			method: 'POST',
			data: data
		});
	}

	userModel.forgotPassword = function(aadhar_id,aadharenc,captcha,tokenF){
		var data = {};
		data._token = csrfToken;
		data.aadhar_id = aadhar_id;
		data.adharenc = aadharenc;
		data.forget = 1;
		data.captchafp = captcha;
		data.tokenF = tokenF;
		return $http({
			url: baseUrl+'forgotpassword',
			method: 'POST',
			data: data
		});
	}

	userModel.encryptAdhar = function(adhar){
		var res = adhar.replace(/0/g,"@").replace(/1/g,"#").replace(/2/g,"$").replace(/3/g,"%").replace(/4/g,"&").replace(/5/g,"m").replace(/6/g,"a").replace(/7/g,"k").replace(/8/g,"d").replace(/9/g,"e");
		var res1 = btoa(res);
		return res1;
	}

	userModel.encryptBen = function(adhar){
		var res = adhar.replace(/0/g,"@").replace(/1/g,"#").replace(/2/g,"!").replace(/3/g,"%").replace(/4/g,"&").replace(/5/g,"m").replace(/6/g,"a").replace(/7/g,"k").replace(/8/g,"d").replace(/9/g,"e");
		var res1 = btoa(res);
		return res1;
	}

	userModel.decryptBen = function(adhar){
		var adhar=atob(adhar);
		var adhar1=adhar.replace(/@/g,"0").replace(/#/g,"1").replace(/!/g,"2").replace(/%/g,"3").replace(/&/g,"4").replace(/m/g,"5").replace(/a/g,"6").replace(/k/g,"7").replace(/d/g,"8").replace(/e/g,"9");
         return adhar1;
          //var adhar1=str_replace(array('@','#','$','%','&','m','a','k','d','e'),array('0','1','2','3','4','5','6','7','8','9'),$adhar);
	}

	userModel.validate_loi = function(app_no){
		var data = {};
		data._token = csrfToken;
		data.app_no = app_no;
		return $http({
			url: baseUrl+'validate_loi',
			method: 'POST',
			data: data
		});
	}
	userModel.check_payment = function(){
		/*var data = {};
		data._token = csrfToken;
		data.app_no = app_no;*/
		return $http({
			url: baseUrl+'api/check_payment',
			method: 'POST',
			//data: data
		});
	}
	userModel.payment_gateway = function(){
		/*var data = {};
		data._token = csrfToken;
		data.app_no = app_no;*/
		return $http({
			url: baseUrl+'api/payment_gateway',
			method: 'POST',
			//data: data
		});
	}
	userModel.payment_gateway_new = function(){
		/*var data = {};
		data._token = csrfToken;
		data.app_no = app_no;*/
		return $http({
			url: baseUrl+'api/payment_gateway_new',
			method: 'POST',
			//data: data
		});
	}
	
	userModel.resendOTP = function(mobile_no=''){
		var data = {};
		data._token = csrfToken;
		data.mobile_no = mobile_no;
		return $http({
			url: baseUrl+'resendOTP',
			method: 'POST',
			data: data
		});
	}

	userModel.generateOTP = function(mobile_no, userinfo='', addrInfo=''){
		var data = {};
		data._token = csrfToken;
		data.mobile_no = mobile_no;
		if (userinfo!='') {
			data.userinfo = userinfo;
		}
		if (addrInfo!='') {
			data.addrInfo = addrInfo;
		}
		//console.log(data);
		return $http({
			url: baseUrl+'generateOTP',
			method: 'POST',
			data: data
		});
	}

    userModel.aadharDetails = function(data,checkapi){
		return $http({
			headers: {
				'Content-Type' : 'application/json'
			},
			url: baseUrl+'aadharverify',
			method: 'POST',
			data: data
		})

	}

	userModel.checkPassLog = function(password,old_password,adhar){
		var data = {};
		data.password = password;
		data.old_password = old_password;
		data.aadhar = adhar;
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'CheckPasswordLog',
			method: 'POST',
			data: data
		})
	}

	userModel.change_password = function(password,old_password,aadhar_data) { 
		var data = {};
		data.password = password;
		data.old_password = old_password;
		data.aadhar_data = aadhar_data;
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'ChangePassword',
			method: 'POST',
			data: data
		})
	}
	userModel.password_log = function(adhar,password,old_password){
		var data = {};
		data.password = password;
		data.old_password = old_password;
		data.aadhar = adhar;
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'PasswordLog',
			method: 'POST',
			data: data
		})
	}
	userModel.addOreditProduct = function(data, contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'addproduct',
			method:'POST',
			data: data
		});
	}
	userModel.viewProduct = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'viewproduct',
			method:'POST',
			data: data
		});
	}
	userModel.deleteImg = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'deleteproduct',
			method:'POST',
			data: data
		});
	}
	userModel.DisableOrEnable = function(status,id){ 
		var data = {};
		data._token = csrfToken;
		data.status = status;
		data.id = id;
		return $http({
			url: baseUrl+'DisableOrEnable',
			method: 'POST',
			data:data
		});
	}
	// userModel.changeJsPwd = function(data) {
	// 	data._token = csrfToken;
	// 	return $http({
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		},
	// 		url: baseUrl+'admin/user/change-js-pwd',
	// 		method: 'POST',
	// 		data: data
	// 	})
	// }
	userModel.getDistrict = function (fund_id) {
		var data = {};
		data._token = csrfToken;
		data.fund_id = fund_id;
		return $http({
			url: baseUrl+'district_id',
			method: 'POST',
			data:data
		});
	}
	userModel.viewSanctionedLetter = function(bene_id,app_no){
		var data = {};
		data._token = csrfToken;
		data.bene_id = bene_id;
		data.app_no = app_no;
		return $http({
			url: baseUrl+'viewSanctionedLetter',
			method: 'POST',
			data:data
		});
	}
    userModel.dynamicvaluescalculations=function(sanction_loan_amount,rate_of_interest,tenure,schemeapplid,applno,schemeId,emimonth,fix_loan_amt){
    	//alert(emimonth);
        var data = {};
		data._token = csrfToken;
		data.sanction_loan_amount = sanction_loan_amount;
		data.rate_of_interest = rate_of_interest;
		data.tenure = tenure;
		data.schemeapplid = schemeapplid;
		data.applno = applno;
		data.schemeId = schemeId;
		data.emimonth=emimonth;
		data.fix_loan_amt=fix_loan_amt;
		return $http({
			url: baseUrl+'dynamicvaluescalculations',
			method: 'POST',
			data:data
		});
	}
	userModel.uploadSanctionLetter = function(data,contentType){
		
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'uploadSanctionLetter',
			method: 'POST',
			data: data
		});
	}
	userModel.uploadAdditionalSanctionLetter = function(data,contentType){
		
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'uploadAdditionalSanctionLetter',
			method: 'POST',
			data: data
		});
	}
    userModel.claimformdetails = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'claimformdetails',
			method: 'POST',
			data: data
		});
	}
	userModel.editClaimFormDetails = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'editClaimFormDetails',
			method: 'POST',
			data: data
		});
	}
	userModel.updateClaimDeclaration = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'updateClaimDeclaration',
			method: 'POST',
			data: data
		});
	}
	userModel.getIncompleteDocumentDetails =function(scheme_application_no, task_stage_id=''){
    	var data = {};
		data._token = csrfToken;
		data.scheme_application_no = scheme_application_no;
		data.task_stage_id = task_stage_id;
		return $http({
			url: baseUrl+'incompeletdocumentdetails',
			method: 'POST',
			data: data
		});
    }

    userModel.getLoiRejctedComment =function(reject_data){
    	var data = {};
		data._token = csrfToken;
		data.reject_data = reject_data;
		return $http({
			url: baseUrl+'getLoiRejctedComment',
			method: 'POST',
			data: data
		});
    }
    
	userModel.getbankdetail = function(application_no,beneficiary_id){
		var data={};
		data._token = csrfToken;
		data.application_no=application_no;
		data.beneficiary_id=beneficiary_id;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'getbankdetail',
			method: 'POST',
			data: data
		});
	}
	userModel.savebankdetails = function(data){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'savebankdetails',
			method: 'POST',
			data: data
		});
	}
	userModel.defaulterfieldslist= function(applicationno,scheme_id){
		//alert(applicationno);
		var data={};
		data._token = csrfToken;
		data.application_no=applicationno;
		data.scheme_id=scheme_id;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'defaulterfieldslist',
			method: 'POST',
			data: data
		});
	}
	userModel.defaulterfields=function(applicationno,schemeid){
		//alert(applicationno);
		var data={};
		data._token = csrfToken;
		data.application_no=applicationno;
		data.schemeid=schemeid;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'defaulterfields',
			method: 'POST',
			data: data
		});
	}
/*userModel.get_captcha=function(){
		var data = {};
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'get_captcha',
			method: 'POST',
			data: data
		});
	}*/

	userModel.get_captcha = function(data){
		return 	$http({
				url:baseUrl+'get-captcha',
				method: 'POST',
				data:{'random_str':data.random_str},
				responseType: 'arraybuffer'
			});
	}

	userModel.generateWordDoc = function(wordData){
		var data = {};
		data._token = csrfToken;
		data.word = wordData;

		console.log(data);
		return $http({
			headers: {
				'Content-Type': "application/json",
			},
			url: baseUrl+'generateWordDocument',
			method: 'POST',
			data: data
		});
	}
	userModel.addTicket = function(ticketData, contentType){
		var data = {};
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'addTicket',
			method: 'POST',
			data: ticketData
		});
	}
	userModel.getTicketDetail = function(id='', beneficiary_id='', flag=''){
		var data = {};
		data._token = csrfToken;
		if (id != '') {
			data.ticketId = id;
		}
		if (beneficiary_id != '') {
			data.beneficiaryId = beneficiary_id;
		}
		if (flag != '') {
			data.flag = flag;
		}
		//alert(id);
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'getTicketDetail',
			method: 'POST',
			data: data
		});
	}
	userModel.deletTicket = function(id){
		var data = {};
		data._token = csrfToken;
		data.ticketId = id;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'deletTicket',
			method: 'POST',
			data: data
		});
	}

	userModel.getGuestUserDetail = function(userid){
		var data = {};
		data._token = csrfToken;
		data.userid = userid;
		console.log(data);
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'getUserDetail',
			method: 'POST',
			data: data
		});
	}
	userModel.addGuestUser = function(guestData, contentType){
		var data = {};
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'addGuestUser',
			method: 'POST',
			data: guestData
		});
	}
	userModel.getBeneficiaryDetail = function(beneficiary_id){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.beneficiary_id = beneficiary_id;
		return $http({
			url: baseUrl+'getBeneficiaryDetail',
			method: 'POST',
			data: data
		});
	}
	userModel.getTicketCategory = function(){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		return $http({
			url: baseUrl+'getTicketCategory',
			method: 'POST',
			data: data
		});
	}
	userModel.getTicketSubCategory = function(){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		return $http({
			url: baseUrl+'getTicketSubCategory',
			method: 'POST',
			data: data
		});
	}
	userModel.getTicketComments=function(ticketId, status=''){
		//alert(scheme_application_no);
		var data = {};
        data._token = csrfToken;
        data.ticketId = ticketId;
        if (status!='') {
        	data.status=status;
        }
        //console.log(scheme_id.to_date);
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'admin/getTicketComments',
			method: 'POST',
			data: data
		})
    }
    userModel.auBankIntegrationPennyDropApi = function(sanctionDetails,contentType){
  		//alert("auBankIntegrationNeftApi usermodel");
   		var data={};
		data._token = csrfToken;
		data.sanctionDetails = sanctionDetails;
		//console.log(data);
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'auBankPennyDrop',
			method: 'POST',
			data: data
		});
    }
     userModel.auBankMiniStatement = function(acc_no, app_id, contentType){
   //	alert("auBankIntegrationNeftApi usermodel");
   		var data={};
		data._token = csrfToken;
		data.acc_no = acc_no;
		data.app_id = app_id;
		//console.log(data);
		return $http({
			headers: {
				'Content-Type': contentType
			},
			url: baseUrl+'auBankMiniStatement',
			method: 'POST',
			data: data
		});
    }

    userModel.add_product = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'add-product',
			method: 'POST',
			data: data
		});
	}
	userModel.productdetails = function(product_id){
		var data = {};
		data._token = csrfToken;
		data.product_id = product_id;
		return $http({
			url: baseUrl+'productdetails',
			method: 'POST',
			data: data
		});
	}

/*	schemeModel.schemeApplication = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'scheme-application',
			method: 'POST',
			data: data
		});
	}*/
	return userModel;
}]);