app.factory('pagesModel', ['$http','$stateParams','$location', function($http,$stateParams,$location) {
	pagesModel = {};

	pagesModel.getPageByName = function(pagesname){
		//console.log(baseUrl+'pages/'+ pagesname);
		return $http.get(baseUrl+'pages/'+ pagesname);
	}

	pagesModel.getPageData = function(pagesname,key){
		return $http.get(baseUrl+'page/'+ pagesname+'/'+key);
	}

	pagesModel.getNews = function(){
		return $http.get(baseUrl+'news');
	}

	// pagesModel.getSinglenews = function(id){
	// 	return $http.get(baseUrl+'single_news/'+id);
	// }

	pagesModel.getPolicy = function(){
		return $http.get(baseUrl+'policy');
	}

	pagesModel.getNotification = function(){
		return $http.get(baseUrl+'notification');
	}

	pagesModel.getNotice = function(){
		return $http.get(baseUrl+'notice');
	}
	
	pagesModel.getSingleDetail = function(id){
		return $http.get(baseUrl+'single_policy/'+id);
	}
	return pagesModel;
}]);