app.factory('masterModel', function($http,$location,$state){

	masterModel = {};

	
	masterModel.LogoutCookie = function(){
		//return $http.get(baseUrl+'');
		/*$http.get(baseUrl+'master/check-session/').success(function(response){
			console.log(response);
			return response;
			/*if(response == "Fail"){
				$state.go('home.index', {}, { reload: true });
				//$state.go('');
			}
	  });	*/
	  return $http.get(baseUrl+'home');
	}

	masterModel.getCountry = function() {
		// body...
		return $http.get(baseUrl+'master/country');
	}
	masterModel.getLabels = function() {
		 	return $http.get(baseUrl+'master/labels');
		}
	masterModel.getStates = function(country_id) {
		// body...
		return $http.get(baseUrl+'master/states/'+country_id);
	}

	masterModel.getDistricts = function(state_id) {
		// body...
		return $http.get(baseUrl+'master/districts/'+state_id);
	}
	masterModel.getDistrictsName = function(district_id) {
		// body...
		return $http.get(baseUrl+'master/districtnane/'+district_id);
	}
	
	masterModel.getTalukas = function(district_id) {
		// body...
		return $http.get(baseUrl+'master/taluka/'+district_id);
	}

	masterModel.getCities = function(taluka_id) {
		// body...
		return $http.get(baseUrl+'master/cities/'+taluka_id);
	}

	masterModel.getNationalities = function() {
		// body...
		return $http.get(baseUrl+'master/nationalities');
	}

	masterModel.getReligions = function() {
		// body...
		return $http.get(baseUrl+'master/religions');
	}

	masterModel.getCaste = function() {
		// body...
		return $http.get(baseUrl+'master/caste');
	}

	masterModel.getSubCastes = function(caste_id) {
		// body...
		return $http.get(baseUrl+'master/sub-caste/'+caste_id);
	}
	//For inserting grievance data in database
    masterModel.grievance_submit = function(grievance) { 
    	//console.log(grievance);
    	var data = {};
		data.grievance = grievance;
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': 'application/json'
			},
			url: baseUrl+'grievance_submit',
			method: 'POST',
			data: data
		})
	}
	masterModel.getmaritalstatus = function() {
		// body...
		return $http.get(baseUrl+'master/marital_status');
	}
	masterModel.getmothertongue = function() {
		// body...
		return $http.get(baseUrl+'master/mother_tongue');
	}
	masterModel.getUploadsUrl = function() {
		// body...
		return $http.get(baseUrl+'master/uploads-url');
	}

	masterModel.getGlobalUrl = function() {
		// body...
		return $http.get(baseUrl+'master/global-url');
	}
	return masterModel;
});
