app.factory('schemeModel', ['$http','$state','$cookies','$cookieStore', function($http,$state,$cookies,$cookieStore){
	schemeModel = {};

	schemeModel.getSchemes = function(){
		/*var data = {};
		//data._token = csrfToken;
		return $http({
			url: baseUrl+'getSchemes',
			method: 'POST',
			data: data
		});*/
		//alert("model");
		return $http.get(baseUrl+'getSchemes');
	}
	schemeModel.getSchemeAppcount = function(){
		/*var data = {};
		//data._token = csrfToken;
		return $http({
			url: baseUrl+'getSchemes',
			method: 'POST',
			data: data
		});*/
		return $http.get(baseUrl+'getSchemeAppcount');
	}
	
    schemeModel.getworkingguidelinesnotices = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'getworkingguidelinesnotices',
			method: 'POST',
			data: data
		});
		
	}
	schemeModel.getclaimformdetails= function(scheme_application_no, loan_type){
		//alert(data.loan_type);
		var data = {};
		data._token = csrfToken;
		data.scheme_application_no = scheme_application_no;
		data.loan_type = loan_type;
		return $http({
			url: baseUrl+'getclaimformdetails',
			method: 'POST',
			data: data
		});
    }
    schemeModel.getclaimformdetailscc= function(scheme_application_no, loan_type){
		//alert("data.loan_type");
		//console.log(scheme_application_no, loan_type);
		var data = {};
		data._token = csrfToken;
		data.scheme_application_no = scheme_application_no;
		data.loan_type = loan_type;
		return $http({
			url: baseUrl+'getclaimformdetailscc',
			method: 'POST',
			data: data
		});
    }		
    schemeModel.getrejectedapplicationdetails=function(scheme_application_no){
    	var data = {};
		data._token = csrfToken;
		data.scheme_application_no = scheme_application_no;
		/*data.beneficiary_id = beneficiary_id;*/
		return $http({
			url: baseUrl+'getrejectedapplicationdetails',
			method: 'POST',
			data: data
		});
    }
    schemeModel.workingguidelinesdesc = function(scheme_id,id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		data.scheme_id = scheme_id;
		return $http({
			url: baseUrl+'workingguidelinesdesc',
			method: 'POST',
			data: data
		});
		
	}
	schemeModel.deleteApplication = function(app_no){
		var data = {};
		data._token = csrfToken;
		data.app_no = app_no;
		return $http({
			url: baseUrl+'deleteApplication',
			method: 'POST',
			data: data
		});
		
	}

	schemeModel.applications = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'applications',
			method: 'POST',
			data: data
		});
	}
	
	schemeModel.applicationData = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'applicationData',
			method: 'POST',
			data: data
		});
	}


	schemeModel.viewScheme = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'viewScheme',
			method: 'POST',
			data: data
		});
	}

    schemeModel.checkrepeatclaimform= function(beneficiary_id){
        var data = {};
		data._token = csrfToken;
		data.beneficiary_id = beneficiary_id;
		return $http({
			url: baseUrl+'checkrepeatclaimform',
			method: 'POST',
			data: data
		});
    }

	schemeModel.schemeEligibilty = function(id){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'schemeEligibilty',
			method: 'POST',
			data: data
		});
	}

	schemeModel.schemeRequiredDoc = function(id){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'schemeRequiredDoc',
			method: 'POST',
			data: data
		});
	}

	schemeModel.getAppRequiredDoc = function(id,url_flag){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		data.url_flag = url_flag;
		return $http({
			url: baseUrl+'getAppRequiredDoc',
			method: 'POST',
			data: data
		});
	}

	schemeModel.getDistricts = function(){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.state_id = 27;
		return $http({
			url: baseUrl+'getDistricts',
			method: 'POST',
			data: data
		});
	}

	schemeModel.getBusinessCategory = function(){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		return $http({
			url: baseUrl+'getBusinessCategory',
			method: 'POST',
			data: data
		});
	}
	schemeModel.getBusinessSubCategory = function(category_id){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.category_id = category_id;
		return $http({
			url: baseUrl+'getBusinessSubCategory',
			method: 'POST',
			data: data
		});
	}
	schemeModel.getNonBankingInstitutes = function(){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		return $http({
			url: baseUrl+'getNonBankingInstitutes',
			method: 'POST',
			data: data
		});
	}

	schemeModel.fundsAvailbility = function(id){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({
			url: baseUrl+'fundsAvailbility',
			method: 'POST',
			data: data
		});
	}
//to check if applicant has already applied for any other scheme
schemeModel.checkapplicantexists = function(aadhar_id){
		// alert("hello");
		var data = {};
		data._token = csrfToken;
		data.aadhar_id = aadhar_id;
		return $http({
			url: baseUrl+'checkApplicantExists',
			method: 'POST',
			data: data
		});
	}
	schemeModel.schemeApplication = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'scheme-application',
			method: 'POST',
			data: data
		});
	}

	schemeModel.schemeApplicationIncomplete = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'scheme-application-incomplete',
			method: 'POST',
			data: data
		});
	}

	schemeModel.schemeApplicationIncompleteIR = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'scheme-application-incomplete-ir',
			method: 'POST',
			data: data
		});
	}

	//schemeApplicationIncomplete

	schemeModel.schemeNominal = function(data,contentType){
		
		//console.log(data.scheme_id);
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'scheme-nominal',
			method: 'POST',
			data: data
		});
	}
	schemeModel.schemeLegalDoc = function(data,contentType){
		
		//console.log(data.scheme_id);
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'scheme-legal-doc',
			method: 'POST',
			data: data
		});
	}
	schemeModel.incompleteDoc = function(data,contentType, comment=''){
		data._token = csrfToken;
		if (comment!='') {
			data.comment = comment;
		}
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'incompleteDoc',
			method: 'POST',
			data: data
		});
	}
	schemeModel.editBusinessDetail = function(data,contentType){

		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'editBusinessDetail',
			method: 'POST',
			data: data
		});
	}
	schemeModel.deleteCompanyDirector = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({	
			headers: {
				'Content-Type': 'application/json',
			},		
			url: baseUrl+'deleteCompanyDirector',
			method: 'POST',
			data: data
		});
	}
	schemeModel.removeGroupDisabilty = function(id){
		//alert("ftyf");
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({	
			headers: {
				'Content-Type': 'application/json',
			},		
			url: baseUrl+'removeGroupDisabilty',
			method: 'POST',
			data: data
		});
	}
	schemeModel.removeGroupDisabiltyCertificates = function(id){
		var data = {};
		data._token = csrfToken;
		data.id = id;
		return $http({	
			headers: {
				'Content-Type': 'application/json',
			},		
			url: baseUrl+'deleteGroupDisabilityCertificates',
			method: 'POST',
			data: data
		});
	}
	schemeModel.deleteincome= function (id,flag) {
		var data ={};
		data._token=csrfToken;
		data.id=id;
		data.flag=flag;
		return $http({
			headers:{
				'Content-Type':'application/json',
			},
			url:baseUrl+'deleteincome',
			method:'POST',
			data:data
		});
	}

	schemeModel.getSchemeApplicationData = function(fund_id,scheme_id,bene_id){
		var data = {};
		data._token = csrfToken;
		data.fund_id = fund_id;
		data.scheme_id = scheme_id;
		data.bene_id = bene_id;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'scheme-application-data',
			method: 'POST',
			data: data
		});
	}
	schemeModel.getSchemeApplicationDataLegal = function(scheme_id,bene_id){
		var data = {};
		data._token = csrfToken;
		data.scheme_id = scheme_id;
		data.bene_id = bene_id;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'scheme-application-data-legal',
			method: 'POST',
			data: data
		});
	}
    schemeModel.uploadSchedule = function(data){
		data._token = csrfToken;
		//console.log(data);
		return $http({
			headers: {
				'Content-Type': undefined
			},
			url: baseUrl+'uploadSchedule',
			method: 'POST',
			data: data
		})
	}

	schemeModel.verifyFamilyAdharDetails = function(data) {
		var aadhar_data = {};
		data._token = csrfToken;
		aadhar_data.data = data;
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'verifyFamilyAdharDetails',
			method: 'POST',
			data: aadhar_data
		})
	}

	schemeModel.addFamilyAdharDetails = function(data,ben_id,aadhar_id,middle_name_check){
		//console.log(data);
		var family_data = {};
		data._token = csrfToken;
		family_data.data = data;
		family_data.bene_id = ben_id; 
		family_data.aadhar_id = aadhar_id; 
		family_data.middle_name_check = middle_name_check; 
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'addFamilyAdharDetails',
			method: 'POST',
			data: family_data
		})
	}

	schemeModel.checkFamilyAdharPresent = function(aadhar_id) {
		var data = {};
		data._token = csrfToken;
		data.aadhar_id = aadhar_id; 
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'checkFamilyAdharPresent',
			method: 'POST',
			data: data
		})
	}
	schemeModel.checkFamilyDetailEntered = function(aadhar_id) {
		var data = {};
		data._token = csrfToken;
		data.aadhar_id = aadhar_id; 
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'checkFamilyDetailEntered',
			method: 'POST',
			data: data
		})
	}

	schemeModel.removeExistFamilyMember = function(member_id) {
		var data = {};
		data._token = csrfToken;
		data.member_id = member_id; 
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'removeExistFamilyMember',
			method: 'POST',
			data: data
		})
	}
	
	
	// userModel.changeJsPwd = function(data) {
	// 	data._token = csrfToken;
	// 	return $http({
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		},
	// 		url: baseUrl+'admin/user/change-js-pwd',
	// 		method: 'POST',
	// 		data: data
	// 	})
	// }

	/*schemeModel.getProductList = function(search){
		var data = {};
		data._token = csrfToken;
		if (search != undefined) {
	        data.offset = (search.offset === undefined) ? 1 : search.offset;
			delete search.offset;
	        data.search = search;
        }
		return $http({
			url: baseUrl+'getProductList',
			method: 'POST',
			data: data
		})
	}*/

	schemeModel.getProductList = function(search){
		var data = {};
        data._token = csrfToken;
        if (search != undefined) {
	        data.offset = (search.offset === undefined) ? 1 : search.offset;
			delete search.offset;
	        data.search = search;
        }
		return $http({
			headers: {
				'Content-Type': 'application/json',
			},
			url: baseUrl+'getProductList',
			method: 'POST',
			data: data
		});
	}
	schemeModel.getDistricts = function(){
		var data = {};
		data._token = csrfToken;
		data.state_id = 27;
		return $http({
			url: baseUrl+'getDistricts',
			method: 'POST',
			data: data
		});
	}
	schemeModel.productdetailsupdate = function(data,contentType){
		data._token = csrfToken;
		return $http({
			headers: {
				'Content-Type': contentType,
			},
			url: baseUrl+'product-details-update',
			method: 'POST',
			data: data
		});
	}
	return schemeModel;
}]);